import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`3 ecom tactics that drove `}{`$`}{`30k in incremental revenue`}</h1>
    <p>{`Yo!`}</p>
    <p>{`A few days ago i told you that we’ve been able to grow my wife’s store to
6-figures over the past few weeks.`}</p>
    <p>{`Here’s what that growth has looked like:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "43.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB75FZEM+0f//EABkQAAMAAwAAAAAAAAAAAAAAAAECAwAQEv/aAAgBAQABBQLGuOUs5G//xAAYEQACAwAAAAAAAAAAAAAAAAAAARESUf/aAAgBAwEBPwFyVen/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAARIgMf/aAAgBAQAGPwIcdr//xAAbEAACAgMBAAAAAAAAAAAAAAAAARExECFBUf/aAAgBAQABPyHRdcV1DE3F+DvCP//aAAwDAQACAAMAAAAQGD//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/ECuDkGT/xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAECAQE/EFCrD//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExYf/aAAgBAQABPxARlhEAkpLax2ATcpnknGHWcT//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "3 month growth",
          "title": "3 month growth",
          "src": "/static/9180892964aaeaf0625a0153135ec514/04bec/3-month-growth.jpg",
          "srcSet": ["/static/9180892964aaeaf0625a0153135ec514/a6407/3-month-growth.jpg 400w", "/static/9180892964aaeaf0625a0153135ec514/a24e6/3-month-growth.jpg 800w", "/static/9180892964aaeaf0625a0153135ec514/04bec/3-month-growth.jpg 1600w", "/static/9180892964aaeaf0625a0153135ec514/369a7/3-month-growth.jpg 1736w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Well, today, i’m gonna show you three specific tactics we’ve used that`}</p>
    <p>{`have driven `}{`$`}{`30K+ of revenue in the past two weeks.`}</p>
    <p><strong parentName="p">{`#1: The Brooklinen Mistake Email`}</strong></p>
    <p>{`A few years ago, Brooklinen (a famous DTC sheets startup) sent out this email
from their intern about Black Friday
(`}<a parentName="p" {...{
        "href": "https://twitter.com/AlanLightfeldt/status/931569928590815232"
      }}><strong parentName="a">{`check this tweet out`}</strong></a>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1336px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7pSKP/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAZEAACAwEAAAAAAAAAAAAAAAAQEQABIWH/2gAIAQEAAT8hrimhH//aAAwDAQACAAMAAAAQgw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEAAQMFAAAAAAAAAAAAAAABABARMSFRYXGR/9oACAEBAAE/EG4Gicg9ljaAMAdV/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Brooklinen Mistake Email",
          "title": "Brooklinen Mistake Email",
          "src": "/static/1110ebdfa3cb246b251eac29e6d15b1e/1eb95/brooklinen-mistake-email.jpg",
          "srcSet": ["/static/1110ebdfa3cb246b251eac29e6d15b1e/a6407/brooklinen-mistake-email.jpg 400w", "/static/1110ebdfa3cb246b251eac29e6d15b1e/a24e6/brooklinen-mistake-email.jpg 800w", "/static/1110ebdfa3cb246b251eac29e6d15b1e/1eb95/brooklinen-mistake-email.jpg 1336w"],
          "sizes": "(max-width: 1336px) 100vw, 1336px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Spoiler alert - it wasn’t a mistake (Brooklinen later confirmed that haha) and
it worked. All accounts are that it drove a shit ton of early black friday
sales.`}</p>
    <p>{`So, Ben had an idea to try this same tactic out. We let a 25% off code slip… and
then posted in our Facebook group:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1102px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2qFB/8QAFxABAQEBAAAAAAAAAAAAAAAAAQASIf/aAAgBAQABBQJO5Yv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAAEDH/2gAIAQEABj8CKv/EABgQAAIDAAAAAAAAAAAAAAAAAAExABAR/9oACAEBAAE/ISqcFFZ//9oADAMBAAIAAwAAABADz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQACAwAAAAAAAAAAAAAAAAEAESFhgf/aAAgBAQABPxBFe5NuJc1Uatn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Brooklinen Tactic Facebook Group",
          "title": "Brooklinen Tactic Facebook Group",
          "src": "/static/18b922a36eed6c30546012a974acd1c4/693cf/brooklinen-tactic-fb-group.jpg",
          "srcSet": ["/static/18b922a36eed6c30546012a974acd1c4/a6407/brooklinen-tactic-fb-group.jpg 400w", "/static/18b922a36eed6c30546012a974acd1c4/a24e6/brooklinen-tactic-fb-group.jpg 800w", "/static/18b922a36eed6c30546012a974acd1c4/693cf/brooklinen-tactic-fb-group.jpg 1102w"],
          "sizes": "(max-width: 1102px) 100vw, 1102px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Just like Brooklinen, people went nuts for a deal that might disappear any
minute.`}</p>
    <p>{`We “let it go unfixed” for 12 hours, and it drove `}{`$`}{`10,000 in sales from this
discount code! One of our biggest sales days ever.`}</p>
    <p>{`Goes to show you, creating some urgency and getting people to chase something
goes a long way.`}</p>
    <p><strong parentName="p">{`Tactic #2: Giving a Gift card to people who have never purchased`}</strong></p>
    <p>{`Every morning me and Ben do a call and brainstorm the day. One morning I asked
“what can we do about all these people who gave us their email but never bought?
Or bought one time and never again?”`}</p>
    <p>{`After all, we know that acquiring new customers and creating “repeat buyers” is
key in ecom..`}</p>
    <p>{`Ben had a great idea. What if we use the Brex tactic again?`}</p>
    <p>{`If you remember
`}<a parentName="p" {...{
        "href": "/app/posts/good-artists-copy-great-artists-steal"
      }}><strong parentName="a">{`day 14 of the all access pass`}</strong></a>{`
when we were raising our fund, we used the brex tactic to close leads.`}</p>
    <p>{`Can this work for ecom?`}</p>
    <p>{`Only one way to find out!`}</p>
    <p>{`Here’s our guesses on how it would work (we always guess before an experiment.
To train our “growth gut” instincts):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "936px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB16Cgf//EABcQAQADAAAAAAAAAAAAAAAAAAEAITH/2gAIAQEAAQUCiWZ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGRAAAwADAAAAAAAAAAAAAAAAAAERMWGh/9oACAEBAAE/IUors4TEf//aAAwDAQACAAMAAAAQAA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhQcH/2gAIAQEAAT8QWmqsTS0JyL6IrTmn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ecom Experiment Growth Guesses",
          "title": "Ecom Experiment Growth Guesses",
          "src": "/static/65fddfa03b3df3f50e732edaeee359fd/5830b/ecom-experiment-growth-guesses.jpg",
          "srcSet": ["/static/65fddfa03b3df3f50e732edaeee359fd/a6407/ecom-experiment-growth-guesses.jpg 400w", "/static/65fddfa03b3df3f50e732edaeee359fd/a24e6/ecom-experiment-growth-guesses.jpg 800w", "/static/65fddfa03b3df3f50e732edaeee359fd/5830b/ecom-experiment-growth-guesses.jpg 936w"],
          "sizes": "(max-width: 936px) 100vw, 936px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Then we hit send on this bad boy
(`}<a parentName="p" {...{
        "href": "https://www.dropbox.com/s/pxtjlvgti41nsrm/brexemailexample.png?dl=0"
      }}><strong parentName="a">{`here's a link to view it a little clearer`}</strong></a>{`)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1299px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdyyQD//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAACAwAAAAAAAAAAAAAAAAABEABBcf/aAAgBAQABPyHIEaf/2gAMAwEAAgADAAAAELPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxAAAgMAAwAAAAAAAAAAAAAAAAERITEQUXH/2gAIAQEAAT8QcJYV+mKZIimi0hdLj//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ecom Brex Tactic",
          "title": "Ecom Brex Tactic",
          "src": "/static/7f67c5a27fdf6fac933d0fef6617eda8/9a80c/ecom-brex-tactic.jpg",
          "srcSet": ["/static/7f67c5a27fdf6fac933d0fef6617eda8/a6407/ecom-brex-tactic.jpg 400w", "/static/7f67c5a27fdf6fac933d0fef6617eda8/a24e6/ecom-brex-tactic.jpg 800w", "/static/7f67c5a27fdf6fac933d0fef6617eda8/9a80c/ecom-brex-tactic.jpg 1299w"],
          "sizes": "(max-width: 1299px) 100vw, 1299px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`And here’s what happened:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "456px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.75000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAepVimj/xAAYEAADAQEAAAAAAAAAAAAAAAAAAUEhMf/aAAgBAQABBQK0WN9P/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEgIf/aAAgBAQAGPwIWz//EABkQAAMBAQEAAAAAAAAAAAAAAAABIRExkf/aAAgBAQABPyHMev0kOhLOFOMpGdP/2gAMAwEAAgADAAAAEEDP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFRcdH/2gAIAQEAAT8QbEdVwpN8uAgsNnOfkC5avbgbBSqnYW1RDZDZZ//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ecom Experiment Results",
          "title": "Ecom Experiment Results",
          "src": "/static/a0c3deb3d79ca28193820d1bce249dee/2fd2a/ecom-experiment-growth-actual.jpg",
          "srcSet": ["/static/a0c3deb3d79ca28193820d1bce249dee/a6407/ecom-experiment-growth-actual.jpg 400w", "/static/a0c3deb3d79ca28193820d1bce249dee/2fd2a/ecom-experiment-growth-actual.jpg 456w"],
          "sizes": "(max-width: 456px) 100vw, 456px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Not bad for 2 hours of work!`}</p>
    <p><strong parentName="p">{`Tactic #3: boost AOV`}</strong></p>
    <p>{`The third tactic we tried was focused around figuring out how to increase the
AOV ( average order value).`}</p>
    <p>{`Rather than discounting (Eg pay us $20 instead of $30), what if we gave them an
incentive to spend more?`}</p>
    <p>{`So we created a gift using a Shopify app called GiftBox, that popped up and told
shoppers`}</p>
    <p>{`“Spend $100 and get a $25 gift card”`}</p>
    <p>{`This is like doing 25% off...but way better:`}</p>
    <ol>
      <li parentName="ol">{`they spend more total dollars.`}</li>
      <li parentName="ol">{`the gift card will get them to come back again in the future for a second
purchase (since they’ll have to use the gift card).`}</li>
    </ol>
    <p>{`This worked magically well, with 150+ orders coming in over
$100. Driving $15,000 in revenue.`}</p>
    <p><strong parentName="p">{`Lastly, three cool things that we’ve been using a lot to figure out how to
drive growth:`}</strong></p>
    <ul>
      <li parentName="ul">{`​`}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://www.theadcreativebank.com/"
          }}>{`https://www.theadcreativebank.com/`}</a></strong>{` is great to see what ads are being run
and this taxonomy chart is really valuable at planning creative execution and
types`}</li>
      <li parentName="ul"><strong parentName="li">{`​`}<a parentName="strong" {...{
            "href": "https://reallygoodemails.com/"
          }}>{`https://reallygoodemails.com/`}</a></strong>{` is a great resource for seeing what brands
do when they send emails`}</li>
      <li parentName="ul">{`Text marketing is all the rage now and we’re just starting to dabble in - so
using this a shit ton to see what others are doing -
`}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://fantastictexts.com"
          }}>{`https://fantastictexts.com`}</a></strong></li>
    </ul>
    <p>{`-shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: '/app/posts/good-artists-copy-great-artists-steal',
        label: 'Brex email tactic'
      }, {
        url: 'https://www.dropbox.com/s/pxtjlvgti41nsrm/brexemailexample.png?dl=0',
        label: 'Brex email for ecom gift card tactic'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://twitter.com/AlanLightfeldt/status/931569928590815232',
        label: 'Brooklinen tactic tweet'
      }, {
        url: 'https://www.theadcreativebank.com/',
        label: 'The Ad Creative Bank'
      }, {
        url: 'https://reallygoodemails.com/',
        label: 'Really Good Emails'
      }, {
        url: 'https://fantastictexts.com/',
        label: 'Fantastic Texts'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.tofu_isnt_just_something_i_like_to_eat.label,
      slug: slugify(postMap.tofu_isnt_just_something_i_like_to_eat.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      